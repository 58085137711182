import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const DefaultNavbarOtherPages = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="navbar">
        <div className="container content"> 
          <div className="logo-navbar-responsive">
            <Link to={"/"}>
              <img
                src="/img/logo/logo.svg"
                style={{ height: "4rem", marginLeft: "0" }}
                alt="Logo r3 ingenieria"
              />
            </Link>
          </div>
          <div className={`navbar-content ${showMenu ? "show" : ""}`}>
            <div className="navbar-display" style={{ width: "100%" }}>
              <div className="logo-navbar">
                <Link to={"/"}>
                  <img
                    src="/img/logo/logo.svg"
                    style={{ height: "4rem", marginLeft: "0" }}
                    alt="Logo r3 ingenieria"
                  />
                </Link>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <div className="navbar-content-responsive">
                  <Link to={"/#home"} onClick={(e) => { scrollToSection('home'); closeMenu(); }}>
                    Inicio
                  </Link>
                  <Link to={"/#about"}  href="#about" onClick={(e) => { scrollToSection('about'); closeMenu(); }}>
                    Nosotros
                  </Link>
                  <Link to={"/#services"} href="#services" onClick={(e) => { scrollToSection('services'); closeMenu(); }}>
                    Servicios
                  </Link>
                  <Link to={"/proyectos"} href="#projects" onClick={(e) => { scrollToSection('projects'); closeMenu(); }}>
                    Proyectos
                  </Link>
                  <a href="#contactus">
                    Contáctanos
                  </a>
                </div>
              </div>
              <div className="d-flex justify-content-end btn-navbar">
                <a className="btn primary btn-navbar" href="#contactus">Cotizar</a>
              </div>
            </div>
          </div>
          <div
            className="mobile-menu-toggle justify-content-end"
            onClick={toggleMenu}
            style={{ marginLeft: "auto" }}
          >
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultNavbarOtherPages;
