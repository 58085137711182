import CotactUs from "../sections/contactus";
import Footer from "../sections/footer";
import FooterCopy from "../components/footer-copy";

import NavbarComponent from "../components/DefaultNavbar";
import Projects from "../sections-projects/projects";
import DefaultNavbarOtherPages from "../components/DefaultNavbarOtherPages";



function ProjectsPage() {
  return (
    <>
      <DefaultNavbarOtherPages/>
      <Projects></Projects>
      <CotactUs />
      <Footer />
      <FooterCopy />
    </>
  );
}

export default ProjectsPage;
