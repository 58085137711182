import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Aos from "aos";

function About() {
  const [sectionText, setSectionText] = useState(
    <Row >
      <h1 className="mb-4">Más de 15 años de experiencia laboral.</h1>
      <p>
        R3 Ingenieria es una empresa joven creada el 15 de octubre del 2009,
        conformada por un equipo de profesionales enfocados en las necesidades
        de nuestros clientes y en la calidad de nuestros servicios, en un corto
        tiempo hemos desarrollado un conjunto de obras importantes en el sector
        público y privado cumpliendo con los costos y tiempos de ejecución.
      </p>
      <p>
        Así mismo contamos con personal técnico profesional para cumplir
        cualquier desafío en tiempos y costo.
      </p>
      <Row className="mt-4">
        <Col sm="6" lg="6">
          <h1 style={{ fontWeight: "900", fontSize: "60px" }}>15+</h1>
          <span>Años de experiencia profesional</span>
        </Col>
        <Col sm="6" lg="6">
          <h1 style={{ fontWeight: "900", fontSize: "60px" }}>500+</h1>
          <span>Proyectos en diferentes áreas</span>
        </Col>
      </Row>
    </Row>
  );
  const [selectedButton, setSelectedButton] = useState("Nosotros");

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  const handleAboutClick = () => {
    setSectionText(
      <Row data-os="fade-down" data-aos-duration="1000">
        <h1 className="mb-4">
          Nuestros mas de 15 años de experiencia laboral.
        </h1>
        <p>
          R3 Ingenieria es una empresa joven creada el 15 de octubre del 2009,
          conformada por un equipo de profesionales enfocados en las necesidades
          de nuestros clientes y en la calidad de nuestros servicios, en un
          corto tiempo hemos desarrollado un conjunto de obras importantes en el
          sector público y privado cumpliendo con los costos y tiempos de
          ejecución.
        </p>
        <p>
          Así mismo contamos con personal técnico profesional para cumplir
          cualquier desafío en tiempos y costo.
        </p>
        <Row className="mt-4">
        <Col sm="6" lg="4">
          <h1 style={{ fontWeight: "900", fontSize: "60px" }}>15+</h1>
          <span>Experiencia profesional</span>
        </Col>
        <Col sm="6" lg="8">
          <h1 style={{ fontWeight: "900", fontSize: "60px" }}>500+</h1>
          <span>Proyectos en diferentes áreas</span>
        </Col>
      </Row>
      </Row>
    );
    setSelectedButton("Nosotros");
  };

  const handleMissionClick = () => {
    setSectionText(
      <Row>
        <h1 className="mb-4">Nuestra Misión de Liderazgo y Compromiso Social</h1>
        <p>
          Aprovechar las oportunidades del mercado y solucionar las necesidades
          de nuestros clientes en una forma competitiva y creativa, gestionando,
          diseñando, construyendo y controlando proyectos habitacionales,
          comerciales, públicos, industriales y de infraestructura.
        </p>
        <p>
          Aplicando conceptos novedosos y orientando correctamente el recurso
          humano, a través de la capacitación y contribución al mejoramiento de
          su calidad de vida para obtener aumento en su productividad laboral y
          progreso en su entorno.
        </p>
      </Row>
    );
    setSelectedButton("Misión");
  };

  const handleVisionClick = () => {
    setSectionText(
      <Row>
        <h1 className="mb-4">Nuestra Visión para un Futuro Sostenible y Exitoso</h1>
        <p>
          Ser una empresa de ingeniería a la vanguardia del sector de la
          construcción a nivel nacional por ofrecer satisfacción y compromiso a
          sus clientes en el desarrollo de sus proyectos.
        </p>
        <p>
          Consolidando mayor valor de la compañía a sus accionistas y
          facilitando oportunidad de crecimiento y desarrollo profesional y
          personal a sus colaboradores.
        </p>
      </Row>
    );
    setSelectedButton("Visión");
  };

  return (
    <section className="about" id="about">
      <Container>
      <Row>
        <Col sm="12" md="6" lg="6" className="mt-5" data-aos="fade-up">
          <div className="band-img-section">
            <div className="band-img-shape"></div>
            <div
              className="band-img-left"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="300"
            ></div>
            <div className="band=img-right">
              <div
                className="band-img-2"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="300"
              ></div>
              <div
                className="band-img-3"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="300"
              ></div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="6"  lg="6" data-aos="fade-up">
          <div className="">
            <span>
              <img
                src="img/icons/arrow.png"
                style={{ width: "25px", marginRight: "10px" }}
              ></img>
              Bienvenidos a nuestra compañía
            </span>
            <div
              className="mt-3 mb-3 d-flex justify-content-between"
              style={{ width: "50%" }}
            >
              <a
                className={`btn-about ${
                  selectedButton === "Nosotros" ? "selected" : ""
                }`}
                onClick={handleAboutClick}
              >
                Nosotros
              </a>
              <a
                className={`btn-about ${
                  selectedButton === "Misión" ? "selected" : ""
                }`}
                onClick={handleMissionClick}
              >
                Misión
              </a>
              <a
                className={`btn-about ${
                  selectedButton === "Visión" ? "selected" : ""
                }`}
                onClick={handleVisionClick}
              >
                Visión
              </a>
            </div>
            {sectionText}
          </div>
        </Col>
      </Row>
      </Container>
    </section>
  );
}

export default About;
