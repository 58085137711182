import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
// Importa tus páginas aquí
import MainPage from './pages/main-page';
import ProjectsPage from './pages/projects-page';
import ProjectDetailsPage from './pages/project-details-page';
import NotFound from './pages/NotFound';
import { useLocation } from 'react-router-dom';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Simula la finalización de la carga después de 2 segundos
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? (
        <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Spinner />
      </div>
      ) : (
        <Router>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<MainPage />} />
            <Route exact path="/proyectos" element={<ProjectsPage />} />
            <Route
              exact
              path="/proyectos/:id"
              element={<ProjectDetailsPage />}
            />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
