import React, { useState } from "react";
import { useParams } from "react-router-dom";
import projectsData from "../components/proyectData"; // Importa los datos de proyectos
import { Col, Container, Row } from "react-bootstrap";
import CotactUs from "../sections/contactus";
import Footer from "../sections/footer";
import FooterCopy from "../components/footer-copy";
import DefaultNavbarOtherPages from "../components/DefaultNavbarOtherPages";
import { LiaSearchPlusSolid } from "react-icons/lia";
import { Link } from "react-router-dom";

function ProjectDetailsPage() {
  const { id } = useParams(); // Obtener el parámetro de la URL

  // Busca el proyecto correspondiente al ID en los datos de proyectos
  const project = projectsData.find((project) => project.id === parseInt(id));

  const [fullscreen, setFullscreen] = useState(false); // Estado para controlar el modo de pantalla completa
  const [fullscreenImageId, setFullscreenImageId] = useState(null); // Estado para almacenar el ID de la imagen en pantalla completa

  // Función para alternar el modo de pantalla completa y almacenar el ID de la imagen
  const toggleFullscreen = (imageId) => {
    setFullscreen(!fullscreen);
    setFullscreenImageId(imageId); // Almacenar el ID de la imagen
  };

  if (!project) {
    return (
      <>
        <DefaultNavbarOtherPages />
        <section style={{ height: "500px",marginTop:"150px" }} >
          <div
            className="d-flex justify-content-center align-items-center mb-5 mt-5"
            
          >
            <h1>PAGINA NO ENCONTRADA</h1>
          </div>
          <div className="d-flex justify-content-center align-items-center"><Link to="/" className="btn primary"> Volver al inicio</Link></div>
        </section>
        <CotactUs />
        <Footer />
        <FooterCopy />
      </>
    );
  }

  return (
    <>
      <DefaultNavbarOtherPages />
      <section>
        <Container>
          <span>
            <img
              src="../img/icons/arrow.png"
              style={{ width: "25px", marginRight: "10px" }}
            ></img>
            Nuestros proyectos
          </span>
          <h1>{project.title}</h1>
          <p>{project.description}</p>
          <Row>
            {project.images.map((image, index) => (
              <Col
                md="4"
                style={{
                  padding: "10px",
                }}
              >
                <div
                  className="img-project-detail"
                  key={index}
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  onClick={() => toggleFullscreen(index)}
                >
                  <div className="img-detail d-flex justify-content-center align-items-center">
                    <LiaSearchPlusSolid />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      {fullscreen && fullscreenImageId !== null && (
        <div className="fullscreen-mode" onClick={() => toggleFullscreen(null)}>
          <img
            src={project.images[fullscreenImageId]}
            style={{ maxWidth: "100%" }}
            alt="Fullscreen Image"
          />
        </div>
      )}
      <CotactUs />
      <Footer />
      <FooterCopy />
    </>
  );
}

export default ProjectDetailsPage;
