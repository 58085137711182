import { Col, Row } from "react-bootstrap";
function CardProyects({ number, title, ubi, classN, onClick, transition }) {
  return (
    <div data-aos="fade-up" data-aos-duration="800" data-aos-delay={transition}>
      <div className={`card-proyect ${classN}`} onClick={onClick}>
        <div className="card-number">
          <h4>{number}</h4>
        </div>
        <div className="card-title">
          <h3>{title}</h3>
          <div>{ubi}</div>
        </div>
      </div>
    </div>
  );
}

export default CardProyects;
