import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NavbarComponent = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Función para desplazarse a la sección correspondiente cuando la página se carga
    const hash = window.location.hash.substr(1); // Obtener el hash de la URL
    if (hash) {
      scrollToSection(hash); // Desplazarse a la sección correspondiente
    }
  }, []); // Ejecutar solo una vez después de que el componente se monte

  return (
    <>
      <div
        className={`navbar absoulteNav ${
          isSticky ? "sticky " : "navbar-nosticky"
        }`}
      >
        <div className="container content">
          <div className="logo-navbar-responsive">
            <Link to={"/"}>
              <img
                src="img/logo/logo.svg"
                style={{ height: "3rem", marginLeft: "0" }}
                alt="Logo"
              />
            </Link>
          </div>
          <div className={`navbar-content ${showMenu ? "show" : ""}`}>
            <div className="navbar-display " style={{ width: "100%" }}>
              <div className="logo-navbar">
                <Link to={"/#home"}>
                  <img
                    src="img/logo/logo.svg"
                    style={{ height: "4rem", marginLeft: "0" }}
                    alt="Logo"
                  />
                </Link>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <div className="navbar-content-responsive">
                  <a href="/#home" onClick={closeMenu}>
                    Inicio
                  </a>
                  <a href="/#about" onClick={closeMenu}>
                    Nosotros
                  </a>
                  <a href="/#services" onClick={closeMenu}>
                    Servicios
                  </a>
                  <a href="#projects" onClick={closeMenu}>
                    Proyectos
                  </a>
                  <a href="#contactus" onClick={closeMenu}>
                    Contáctanos
                  </a>
                </div>
              </div>
              <div className="d-flex justify-content-end btn-navbar">
                <a className="btn primary btn-navbar" href="#contactus">Cotizar</a>
              </div>
            </div>
          </div>
          <div
            className="mobile-menu-toggle justify-content-end"
            onClick={toggleMenu}
            style={{ marginLeft: "auto" }}
          >
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarComponent;
