const projectsData = [
  {
    id: 1,
    title: "Remodelación de exterior",
    description: "Transformamos tu espacio exterior con creatividad y precisión. Desde renovaciones hasta mejoras estéticas.",
    portada:"/img/proyectos/1/2.jpg",

    images: [
      "/img/proyectos/1/1.jpg",
      "/img/proyectos/1/2.jpg",
      "/img/proyectos/1/3.jpg",
      "/img/proyectos/1/4.jpg",
      "/img/proyectos/1/5.jpg",
      "/img/proyectos/1/6.jpg",
      "/img/proyectos/1/7.jpg",
      "/img/proyectos/1/8.jpg",
      "/img/proyectos/1/9.jpg",
      "/img/proyectos/1/10.jpg",
      "/img/proyectos/1/11.jpg",
      "/img/proyectos/1/12.jpg",
      "/img/proyectos/1/13.jpg",
      "/img/proyectos/1/14.jpg",
    ],
  },
  {
    id: 2,
    title: "Remodelación de oficinas",
    description: "Renovamos tus espacios de trabajo para potenciar la productividad y el confort. Con diseños innovadores y ejecución experta.",
    portada:"img/about3.jpg",
    portada:"/img/proyectos/2/1.jpg",

    images: [
      "/img/proyectos/2/1.jpg",
      "/img/proyectos/2/2.jpg",
      "/img/proyectos/2/3.jpg",
      "/img/proyectos/2/4.jpg",
      "/img/proyectos/2/5.jpg",
      "/img/proyectos/2/6.jpg",
      "/img/proyectos/2/7.jpg",
      "/img/proyectos/2/8.jpg",
      "/img/proyectos/2/9.jpg",
      "/img/proyectos/2/10.jpg",
      "/img/proyectos/2/11.jpg",
      "/img/proyectos/2/12.jpg",
      "/img/proyectos/2/13.jpg",
      "/img/proyectos/2/14.jpg",
      "/img/proyectos/2/15.jpg",
      "/img/proyectos/2/16.jpg",
      "/img/proyectos/2/17.jpg",
      "/img/proyectos/2/18.jpeg",
      "/img/proyectos/2/19.jpg",
      "/img/proyectos/2/20.jpg",
      "/img/proyectos/2/21.jpg",
      "/img/proyectos/2/22.jpg",
      "/img/proyectos/2/23.jpg",
      "/img/proyectos/2/24.jpg",
      "/img/proyectos/2/25.jpg",
      "/img/proyectos/2/26.jpg",
      "/img/proyectos/2/27.jpg",
      "/img/proyectos/2/28.jpg",
      "/img/proyectos/2/29.jpg",
      "/img/proyectos/2/30.jpg",
      "/img/proyectos/2/31.jpg",
      "/img/proyectos/2/32.jpg",
      "/img/proyectos/2/33.jpg",
      "/img/proyectos/2/34.jpg",
      "/img/proyectos/2/35.jpg",
      "/img/proyectos/2/36.jpg",
    ],
  },
  {
    id: 3,
    title: "Remodelación de cuarto limpio",
    description: "Optimizamos la funcionalidad y la esterilidad de tus cuartos limpios. Con soluciones innovadoras, garantizamos un entorno seguro y controlado para tus procesos sensibles.",
    portada:"/img/proyectos/3/1.jpg",

    images: [
      "/img/proyectos/3/1.jpg",
      "/img/proyectos/3/2.jpg",
      "/img/proyectos/3/3.jpg",
      "/img/proyectos/3/4.jpg",
      "/img/proyectos/3/5.jpg",
      "/img/proyectos/3/6.jpg",
      "/img/proyectos/3/7.jpg",
      "/img/proyectos/3/8.jpg",
      "/img/proyectos/3/9.jpg",
      "/img/proyectos/3/10.jpg",
      "/img/proyectos/3/11.jpg",
      "/img/proyectos/3/12.jpg",
      "/img/proyectos/3/13.jpg",
      "/img/proyectos/3/14.jpg",
      "/img/proyectos/3/15.jpg",
      "/img/proyectos/3/16.jpg",
      "/img/proyectos/3/17.jpg",
    ],
  },
  {
    id: 4,
    title: "Remodelación de baños",
    description: "Modernizamos tus baños con estilo y funcionalidad. Desde renovaciones básicas hasta diseños personalizados.",
    portada:"/img/proyectos/4/1.jpg",
    images: [
      "/img/proyectos/4/1.jpg",
      "/img/proyectos/4/2.jpg",
      "/img/proyectos/4/3.jpg",
      "/img/proyectos/4/4.jpg",
      "/img/proyectos/4/5.jpg",
      "/img/proyectos/4/6.jpg",
      "/img/proyectos/4/7.jpg",
      "/img/proyectos/4/8.jpg",
      "/img/proyectos/4/9.jpg",
    ],
  },
  {
    id: 5,
    title: "Enjarre",
    description: "Renovamos tus paredes con enjarre experto. Con precisión y cuidado, transformamos cualquier espacio en un lienzo limpio y uniforme, listo para tus proyectos de diseño.",
    portada:"img/proyectos/5/1.jpg",
    images: [
      "/img/proyectos/5/1.jpg",
      "/img/proyectos/5/2.jpg",
      "/img/proyectos/5/3.jpg",
      "/img/proyectos/5/4.jpg",
      "/img/proyectos/5/5.jpg",
      "/img/proyectos/5/6.jpg",
      "/img/proyectos/5/7.jpg",
      "/img/proyectos/5/8.jpg",
      "/img/proyectos/5/9.jpg",
      "/img/proyectos/5/10.jpg",
      "/img/proyectos/5/11.jpg",
      "/img/proyectos/5/12.jpg",
    ],
  },
  {
    id: 6,
    title: "Impermeabilización",
    description: "Protegemos tus estructuras con impermeabilización de primera calidad. Nuestros servicios garantizan una barrera efectiva contra la humedad, preservando la integridad y durabilidad de tu propiedad.",
    portada:"img/proyectos/6/1.jpg",
    images: [
      "/img/proyectos/6/1.jpg",
      "/img/proyectos/6/2.jpg",
      "/img/proyectos/6/3.jpg",
      "/img/proyectos/6/4.jpg",
      "/img/proyectos/6/5.jpg",
      "/img/proyectos/6/6.jpg",
      "/img/proyectos/6/7.jpg",
      "/img/proyectos/6/8.jpg",
      "/img/proyectos/6/9.jpg",
      "/img/proyectos/6/10.jpg",
      "/img/proyectos/6/11.jpg",
      "/img/proyectos/6/12.jpg",
      "/img/proyectos/6/13.jpg",
      "/img/proyectos/6/14.jpg",
      "/img/proyectos/6/15.jpg",
    ],
  },
  {
    id: 7,
    title: "Ampliación para lockers",
    description: "Realizamos una ampliación de lockers para ofrecer mayor espacio de almacenamiento a nuestros clientes. El proyecto incluyó diseño personalizado y ejecución experta para garantizar funcionalidad y comodidad.",
    portada:"img/proyectos/7/1.jpg",
    images: [
      "/img/proyectos/7/1.jpg",
      "/img/proyectos/7/2.jpg",
      "/img/proyectos/7/3.jpg",
      "/img/proyectos/7/4.jpg",
      "/img/proyectos/7/5.jpg",
      "/img/proyectos/7/6.jpg",
      "/img/proyectos/7/7.jpg",
      "/img/proyectos/7/8.jpg",
      "/img/proyectos/7/9.jpg",
      "/img/proyectos/7/10.jpg",
      "/img/proyectos/7/11.jpg",
      "/img/proyectos/7/12.jpg",
      "/img/proyectos/7/13.jpg",
      "/img/proyectos/7/14.jpg",
    ],
  },
  
  {
    id: 8,
    title: "Barandal y bolardos de herrería",
    description: "Fabricamos e instalamos un barandal de herrería para mejorar la seguridad y el estilo de tu propiedad.",
    portada:"img/proyectos/8/1.jpg",
    images: [
      "/img/proyectos/8/1.jpg",
      "/img/proyectos/8/2.jpg",
      "/img/proyectos/8/3.jpg",
      "/img/proyectos/8/4.jpg",
      "/img/proyectos/8/5.jpg",
    ],
  },
  {
    id: 9,
    title: "Remodelación de oficinas Sindicatura",
    description: "Llevamos a cabo una remodelación integral de las oficinas de la Sindicatura. Nuestro trabajo incluyó diseño ergonómico, instalaciones modernas y acabados de alta calidad para mejorar la eficiencia y el ambiente laboral.",
    portada:"img/proyectos/9/1.jpg",
    images: [
      "/img/proyectos/9/1.jpg",
      "/img/proyectos/9/2.jpg",
      "/img/proyectos/9/3.jpg",
      "/img/proyectos/9/4.jpg",
      "/img/proyectos/9/5.jpg",
      "/img/proyectos/9/6.jpg",
      "/img/proyectos/9/7.jpg",
      "/img/proyectos/9/8.jpg",
      "/img/proyectos/9/9.jpg",
      "/img/proyectos/9/10.jpg",
      "/img/proyectos/9/11.jpg",
      "/img/proyectos/9/12.jpg",
      "/img/proyectos/9/13.jpg",
      "/img/proyectos/9/14.jpg",
      "/img/proyectos/9/15.jpg",
      "/img/proyectos/9/16.jpg",
      "/img/proyectos/9/17.jpg",
      "/img/proyectos/9/18.jpg",
      "/img/proyectos/9/19.jpg",
      "/img/proyectos/9/20.jpg",
      "/img/proyectos/9/21.jpg",
      "/img/proyectos/9/22.jpg",
      "/img/proyectos/9/23.jpg",
      "/img/proyectos/9/24.jpg",
      "/img/proyectos/9/25.jpg",
      "/img/proyectos/9/26.jpg",
      "/img/proyectos/9/27.jpg",
    ],
  },
  {
    id: 10,
    title: "Ampliación",
    description: "Realizamos una ampliación estructural para adaptarnos a tus necesidades de espacio. Nuestro enfoque incluye diseño personalizado y construcción eficiente para maximizar la funcionalidad .",
    portada:"img/proyectos/10/1.jpg",
    images: [
      "/img/proyectos/10/1.jpg",
      "/img/proyectos/10/2.jpg",
      "/img/proyectos/10/3.jpg",
      "/img/proyectos/10/4.jpg",
      "/img/proyectos/10/5.jpg",
    ],
  },
];

export default projectsData;
