import { Link } from "react-router-dom";

function ProyectCardSingle({ id, texto, portada,descripcion }) {
  return (
    <Link to={`/proyectos/${id}`}>
      <div
        className="card-proyecto-singe"
        style={{
          backgroundImage: `url(${portada})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "300px",
          position: "relative",
        }}
      >
        <div className="card-project-page">
         <h4> {texto}</h4>
          <div className="hidden">
            <p>{descripcion}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ProyectCardSingle;
