import Header from "../sections/header";
import Solution from "../sections/solution";
import About from "../sections/about";
import CotactUs from "../sections/contactus";
import Footer from "../sections/footer";
import FooterCopy from "../components/footer-copy";
import ProjectsV2 from "../sections/proyect v2";
import NavbarComponent from "../components/DefaultNavbar";
import Band from "../sections/band";
import ServicesV2 from "../sections/services v2";

function MainPage() {
  return (
    <>
      <NavbarComponent />
      <Header
        title={"Pasión por la ingeniería, compromiso con la calidad"}
        text={
          "En R3 Ingeniería tenemos pasión por la ingeniería y nos comprometemos con la calidad en cada trabajo realizado."
        }
      />
      <About />
      <Band/>
      <ServicesV2/>
      <ProjectsV2 />
      {/*<Solution/>*/}
      <CotactUs />
      <Footer />
      <FooterCopy />
    </>
  );
}

export default MainPage;
