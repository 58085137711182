import React from "react";
import { Container, Row, Col } from "react-bootstrap"; // Importa Row y Col de react-bootstrap
import ProyectCardSingle from "../pages/proyectCardSingle";
import projectsData from "../components/proyectData";

function Projects() {
  return (
    <section style={{ backgroundColor: "#f0f0f0" }}>
      <Container>
        <Row className="mb-3">
          <span>
            <img
              src="img/icons/arrow.png"
              style={{ width: "25px", marginRight: "10px" }}
            ></img>
            Nuestros proyectos
          </span>
          <h1>Descubre la excelencia en cada detalle</h1>
          <p>
          Aquí te presentamos una selección de algunos de nuestros proyectos más destacados. Desde diseños innovadores hasta ejecuciones impecables, cada obra refleja nuestro compromiso con la excelencia y la calidad.
          </p>
        </Row>
        <Row>
          {projectsData.map((project) => (
            <Col key={project.id} md={4} className="mb-4">
              <ProyectCardSingle
                foto={project.portada}
                texto={project.title}
                portada={project.portada}
                id={project.id}
                descripcion={project.description}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Projects;
