import React from "react";

function FooterCopy() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="centered footer-copy">
      <a
        href="http://www.inteligen-t.com"
        className="logo-inteligen-t"
        style={{ marginLeft: "5px" }}
      >
        <img src="/img/logo/inteligen-t.svg" alt="logo inteligen-t"></img>
      </a>
      <br></br>
      Copyright © {currentYear}, Todos los derechos reservados R3 Ingenieria
    </div>
  );
}

export default FooterCopy;
