import React, { useState } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import emailjs from "emailjs-com";

function ContactForm({placeHolder_nombre,placeHolder_telefono,placeHolder_correo,placeHolder_mensaje,placeHolder_btn}) {
  const [showModal, setShowModal] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    phone: "",
    reply_to: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    from_name: "",
    phone: "",
    reply_to: "",
    message: "",
  });

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      from_name: e.target[0].value,
      phone: e.target[1].value,
      reply_to: e.target[2].value,
      message: e.target[3].value,
    };

    const newErrors = {};

    if (formData.from_name.trim() === "") {
      newErrors.from_name = "Por favor, escriba su nombre.";
    } else {
      newErrors.from_name = "";
    }

    if (formData.message.trim() === "") {
      newErrors.message = "Por favor ingrese un mensaje.";
    } else {
      newErrors.message = "";
    }

    if (!validateEmail(formData.reply_to)) {
      newErrors.reply_to = "Por favor, introduce una dirección de correo electrónico válida.";
    } else {
      newErrors.reply_to = "";
    }

    if (formData.phone.trim().length !== 10 || isNaN(formData.phone.trim())) {
      newErrors.phone = "Ingrese un número de teléfono válido (10 dígitos).";
    } else {
      newErrors.phone = "";
    }

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    emailjs
      .send(
        "service_34nlvm2", // Reemplaza con tu Service ID para el correo al cliente
        "Cliente", // Reemplaza con tu Template ID para el correo al cliente
        formData,
        "axd2TUKwxZE6Y-uB5" // Reemplaza con tu User ID para el correo al cliente
      )
      .then(() => {
        emailjs
          .send(
            "service_34nlvm2", // Reemplaza con tu Service ID para el correo al usuario
            "Usuario", // Reemplaza con tu Template ID para el correo al usuario
            {
              ...formData,
              to_email: "contacto@r3ingenieria.com", // Reemplaza con tu dirección de correo
            },
            "axd2TUKwxZE6Y-uB5" // Reemplaza con tu User ID para el correo al usuario
          )
          .then(() => {
            handleOpenModal();
            setToSend({
              from_name: "",
              phone: "",
              reply_to: "",
              message: "",
            });
          })
          .catch((error) => {
            console.error("Error sending email to the user:", error);
          });
      })
      .catch((error) => {
        console.error("Error sending email to the client:", error);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="card-contact">
        <Col sm={"12"}>
          <input
            type="text"
            placeholder={placeHolder_nombre}
            name="from_name"
            value={toSend.from_name}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.from_name}</p>
        </Col>
        <Col sm={"12"}>
          <input
            type="number"
            placeholder={placeHolder_telefono}
            maxLength={10}
            name="phone"
            value={toSend.phone}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.phone}</p>
        </Col>
        <Col sm={"12"}>
          <input
            type="mail"
            placeholder={placeHolder_correo}
            name="reply_to"
            value={toSend.reply_to}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.reply_to}</p>
        </Col>
        <Col sm={"12"}>
          <textarea
            type="text"
            placeholder={placeHolder_mensaje}
            name="message"
            value={toSend.message}
            onChange={handleChange}
            style={{height:"120px"}}
          ></textarea>
          <p className="error-msj">{errors.message}</p>
        </Col>
        <Col sm={"12"}>
          <div className="d-flex"><button
            type="submit"
            className="primary btn"
          >
            {placeHolder_btn}
          </button></div>
        </Col>
      </form>
      {/*<button onClick={handleOpenModal}>Debug</button> */}
      <Modal show={showModal} onHide={handleCloseModal} style={{zIndex:"999999",marginTop:"200px"}}>
        <Modal.Header closeButton>
          <Modal.Title>Correo enviado correctamente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        ¡Muchas gracias por tu preferencia!
          <br></br>
          <br></br>
          Alguien de nuestro equipo se comunicará con usted en breve.
        </Modal.Body>

        <Modal.Footer >
          <div className="d-flex align-content-end">
          <button
            className="third btn"
            onClick={handleCloseModal}
            style={{ width: "100%" }}
          >
            Entiendo
          </button>
          </div>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContactForm;
