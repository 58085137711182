import React from "react";
import CotactUs from "../sections/contactus";
import Footer from "../sections/footer";
import FooterCopy from "../components/footer-copy";
import DefaultNavbarOtherPages from "../components/DefaultNavbarOtherPages";
import { Link } from "react-router-dom";

function NotFound() {

    return (
      <>
        <DefaultNavbarOtherPages />
        <section style={{ height: "500px",marginTop:"150px" }} >
          <div
            className="d-flex justify-content-center align-items-center mb-5 mt-5"
            
          >
            <h1>PAGINA NO ENCONTRADA</h1>
          </div>
          <div className="d-flex justify-content-center align-items-center"><Link to="/" className="btn primary"> Volver al inicio</Link></div>
        </section>
        <CotactUs />
        <Footer />
        <FooterCopy />
      </>
    );
  }


export default NotFound;
