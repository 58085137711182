import React from "react";
import { Card, Col, Row } from "react-bootstrap";
function CardServices({ title, text, img, icon }) {
  return (

      <div
        className="card-servicev2"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="title">
          <div className="icon">
            <img src={icon}></img>
          </div>
          <h4>{title}</h4>
          <div className="hidden">
            <div className="content">
            <p>
              {text}
            </p>
            </div>
          </div>
        </div>
      </div>

  );
}

export default CardServices;
