import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Col, Row, Modal } from "react-bootstrap";

function Band() {
  return (
    <div
      className="call-action"
      style={{
        backgroundImage: "url(img/call-to-action.webp",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat:"no-repeat",
        backgroundAttachment:"fixed"
      }}
    >
      <Container className="center-div">
        <Row style={{ height: "100%" }}>
          <Col sm="12" md="8">
            <h1>Desde diseño hasta construcción</h1>
            <p style={{ fontWeight: "600", color: "#fff" }}>
              Estamos aquí para ayudarte en cada paso del camino
            </p>
          </Col>
          <Col sm="12" md="4" className="d-flex justify-content-end mt-3">
            <a className="btn primary" href="#contactus" style={{height:"4rem"}}>¡Contáctanos hoy!</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Band;
