import Container from "react-bootstrap/Container";
import {Col, Row } from "react-bootstrap";
import CardServices from "../components/card-servicesv2";

function Service1() {
  return (
    <ul>
      <li>Construcción y remodelación de espacios</li>
      <li>Remodelación de edificios</li>
      <li>Remodelación de oficinas</li>
      <li>Remodelación de cuartos limpios</li>
    </ul>
  );
}
function Service2() {
  return (
    <ul>
      <li>Infraestructura general</li>
      <li>Plomería</li>
      <li>Impermeabilización</li>
      <li>Estructuras mecánicas</li>
      <li>Diseño arquitectónico</li>
    </ul>
  );
}
function Service3() {
  return (
    <ul>
      <li>Proyectos eléctricos</li>
      <li>Trámites ante CFE para aumentos de carga y demanda</li>
      <li>Subestaciones</li>
      <li>Mantenimiento eléctrico</li>
      <li>Instalaciones de ramos y compresores de aire comprimido</li>
      <li>Sistemas hidráulicos y chillers</li>
      <li>Maniobra y reubicaciones de maquinaria</li>
    </ul>
  );
}
function ServicesV2() {
  return (
    <section
      className="services"
      id="services"
      style={{ backgroundColor: "#fdfdfd" }}
    >
      <Container>
        <Row data-aos="fade-up"
              data-aos-duration="800">

            <span>
              <img
                src="img/icons/arrow.png"
                style={{ width: "25px", marginRight: "10px" }}
              ></img>
              Nuestros Servicios
            </span>
            <div className="mb-3">
              <h1>Descubre lo que podemos hacer para ti</h1>
              <p>
                En nuestra empresa ofrecemos una amplia gama de servicios de
                construcción e ingeniería para satisfacer las necesidades de
                nuestros clientes.
              </p>
            </div>

        </Row>
        <Row>
          <Col sm="12" md="12" lg="4" data-aos="fade-up" data-aos-duration="800">
            <CardServices
              title={"Obra civil"}
              icon={"img/icons/teodolito.png"}
              text={<Service1 />}
              img={"img/obra-civil.jpg"}
            />
          </Col>
          <Col sm="12" md="12" lg="4" data-aos="fade-up" data-aos-duration="1200">
            <CardServices
              title={"Infraestructura y Diseño"}
              icon={"img/icons/diseno.png"}
              text={<Service2 />}
              img={"img/diseno.webp"}
            />
          </Col>
          <Col sm="12" md="12" lg="4" data-aos="fade-up" data-aos-duration="1600">
            <CardServices
              title={"Obra electromecánica"}
              icon={"img/icons/construccion.png"}
              text={<Service3 />}
              img={"img/sala-limpia.jpg"}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ServicesV2;
