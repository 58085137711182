import React, { useState, useEffect, useRef } from "react";

function DefaultCarousel({ videos }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(null); // Almacena el índice anterior
  const videoRefs = useRef([]);

  const handleVideoButtonClick = (index) => {
    if (index !== currentIndex) {
      setPrevIndex(currentIndex);
      setCurrentIndex(index);
    }
  };

  useEffect(() => {
    // Pausa el video anterior si existe
    if (prevIndex !== null && videoRefs.current[prevIndex]) {
      videoRefs.current[prevIndex].pause();
    }
    
    // Reproduce el video actual si existe
    const currentVideo = videoRefs.current[currentIndex];
    if (currentVideo) {
      currentVideo.play();
    }
  }, [currentIndex, prevIndex]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPrevIndex(currentIndex);
      setCurrentIndex((currentIndex + 1) % videos.length);
    }, 15000); // Cambia el video cada 15 segundos

    return () => clearTimeout(timer);
  }, [currentIndex, videos.length]);

  return (
    <div className="video-wrapper">
      <div className="controls">
        {videos.map((video, btnIndex) => (
          <button
            key={btnIndex}
            onClick={() => handleVideoButtonClick(btnIndex)}
            className={`video-button ${btnIndex === currentIndex ? "video-button-active" : ""}`}
          ></button>
        ))}
      </div>
      <div className="video-overlay"></div>
      {videos.map((video, index) => (
        <div
          key={index}
          className={`video-slide ${index === currentIndex ? "active" : ""}`}
          style={{ display: index === currentIndex ? "block" : "none" }}
        >
          <video
            ref={(ref) => (videoRefs.current[index] = ref)}
            autoPlay={index === currentIndex}
            loop
            muted
            preload="metadata"
            src={video.src}
            type={video.type}
          >
            Tu navegador no soporta la etiqueta de video.
          </video>
        </div>
      ))}
    </div>
  );
}

export default DefaultCarousel;
